<template>
  <b-card no-body>
    <b-card-body class="pb-0">
      <div class="d-flex justify-content-start mb-3">
        <div class="mr-2">
          <b-card-text class="mb-50">
            {{$t('instagram.male')}}

          </b-card-text>
          <h3 class="font-weight-bolder">
            <span style="color:#5cb3ff">{{ percentage_male }}%</span>
          </h3>
        </div>
        <div class="mr-2">
          <b-card-text class="mb-50">
            {{$t('instagram.female')}}

          </b-card-text>
          <h3 class="font-weight-bolder">
            <span style="color:#E75480">{{ percentage_female }}%</span>
          </h3>
        </div>
      </div>
      <vue-apex-charts v-if="change_graph" :key="change_graph" type="line" height="200" :options="chartOptions" :series="series"></vue-apex-charts>
    </b-card-body>
  </b-card>
</template>

<script>

import { BCard, BCardBody, BCardText  } from 'bootstrap-vue'
import VueApexCharts from 'vue-apexcharts'
import utils from '@/libs/utils'

export default {
  props: {
    info: {
      type: Array,
      required: true
    },
  },
  components: {
    BCard,
    BCardBody,
    BCardText,
    VueApexCharts
  },
  data () {
    return {
      utils,
      categories: [],
      series: [{name: `${this.$t('instagram.male')}`, data: []}, {name: `${this.$t('instagram.female')}`, data: []}],
      percentage_male: 0,
      percentage_female: 0,
      chartOptions: {
        chart: {
          toolbar: { show: false },
          zoom: { enabled: false },
          type: 'line',
          offsetX: -10,
        },
        stroke: {
          curve: 'smooth',
          dashArray: [0],
          width: [4, 3],
        },
        legend: {
          show: false,
        },
        colors: ['#5cb3ff', '#E75480'],
        fill: {
          type: 'gradient',
          gradient: {
            shade: 'dark',
            inverseColors: false,
            gradientToColors: ['#5cb3ff', '#E75480'],
            shadeIntensity: 1,
            type: 'horizontal',
            opacityFrom: 1,
            opacityTo: 1,
            stops: [0, 100, 100, 100],
          },
        },
        markers: {
          size: 0,
          hover: {
            size: 5,
          },
        },
        xaxis: {
          title: {
            text: `${this.$t('instagram.ageRange')}`,
            offsetY: -10,
            style: {
              fontWeight: 400,
              fontSize: '1rem',
              color: '#b9b9c3'
            }
          },
          labels: {
            style: {
              colors: '#b9b9c3',
              fontSize: '1rem',
            },
          },
          axisTicks: {
            show: false,
          },
          categories: [],
          axisBorder: {
            show: false,
          },
          tickPlacement: 'on',
        },
        yaxis: {
          tickAmount: 5,
          labels: {
            style: {
              colors: '#b9b9c3',
              fontSize: '1rem',
            },
            formatter(val) {
              return `${val}%`
            },
          },
        },
        grid: {
          borderColor: '#e7eef7',
          padding: {
            top: -20,
            bottom: -10,
            left: 20,
          },
        },
        tooltip: {
          x: { show: false },
        },
      },
      change_graph: false
    }
  },
  created () {
    this.percentage_male = this.info[0].value.toFixed(2);
    this.percentage_female = this.info[1].value.toFixed(2)
    const categories = []
    const serieA = []
    const serieB = []
    this.info[0].by_age_group.map((item) => {
      categories.push(item.group.substring(3, 9))
      serieA.push(item.value)
    })
    this.info[1].by_age_group.map((item) => serieB.push(item.value))
    this.chartOptions.xaxis.categories = categories
    this.series[0].data = serieA;
    this.series[1].data = serieB;

    setTimeout(() => {
      this.change_graph = true;
    }, 100)
  },


}
</script>


<style>
</style>
 