<template>
  <div>
    <b-card id="card-filters-ooh" class="m-0">
      <b-row class="m-0">
        <!-- Disponibilidad -->
        <b-col class="col-12 col-md-6 col-lg filter-outline p-0 ">
          <b-dropdown block no-caret class="dropdown-filters" variant="outline-gray-light" menu-class="w-100" ref="filter_audience_location">
            <template #button-content> 
              <div class="d-flex flex-column justify-content-between h-100">
                <b-form-group class="mb-0">
                  <span class="text-left align-middle color-gray-light d-block mb-1">
                    <feather-icon icon="CheckSquareIcon"></feather-icon>
                    {{$t("search.realty.availability")}} 
                  </span>
                </b-form-group>
                <p class="text-left font-weight-bold m-0">{{ (Object.keys(availability).length > 0) ? availability.text : $t('search.realty.any')}}</p>
              </div>
            </template>
            <b-dropdown-header class="m-0">
              <div>
                <options-filters 
                  :options="$t('search.realty.availability_options')" 
                  @optionsFilter="filterSelect" 
                  :vertical="true"
                  :name_prop="`availability`"
                  :key="update_availability"
                />
              </div>
            </b-dropdown-header>
          </b-dropdown>
        </b-col>

        <!-- Estado -->
        <b-col class="col-12 col-md-6 col-lg filter-outline p-0">
          <b-dropdown block no-caret class="dropdown-filters" variant="outline-gray-light" menu-class="w-100" ref="filter_audience_location" :key="update_location">
            <template #button-content> 
              <div class="d-flex flex-column justify-content-between h-100">
                <b-form-group class="mb-0">
                  <span class="text-left align-middle color-gray-light d-block mb-1">
                    <span class="search-Map-Pin4"></span>
                    {{$t("search.realty.state")}} 
                  </span>
                </b-form-group>
                <p class="text-left font-weight-bold m-0">{{ (Object.keys(state).length > 0) ? state.text : $t('search.realty.any')}}</p>
              </div>
            </template>
            <b-dropdown-header :key="update_location">
              <locations-state-suggest 
                :states="state_options.filter(i => i.value !== null)"
                @location_selected="locationSelected"
                :state="state"
              />
            </b-dropdown-header>
          </b-dropdown>
        </b-col>

        <!-- Categoria -->
        <b-col class="col-12 col-md-6 col-lg filter-outline p-0">
          <b-dropdown block no-caret class="dropdown-filters" variant="outline-gray-light" menu-class="w-100" ref="filter_audience_location">
            <template #button-content> 
              <div class="d-flex flex-column justify-content-between h-100">
                <b-form-group class="mb-0">
                  <span class="text-left align-middle color-gray-light d-block mb-1">
                    <feather-icon icon="TagIcon"></feather-icon>
                    {{$t("search.realty.category")}} 
                  </span>
                </b-form-group>
                <p class="text-left font-weight-bold m-0">{{ (Object.keys(category).length > 0) ? category.text : $t('search.realty.any')}}</p>
              </div>
            </template>
            <b-dropdown-header>
              <options-filters 
                :options="category_options" 
                @optionsFilter="filterSelect" 
                :vertical="true"
                :name_prop="`category`"
                :key="update_category"
              />
            </b-dropdown-header>
          </b-dropdown>
        </b-col>

        <b-col class="col-12 col-md-6 col-lg filter-outline p-0">
          <b-dropdown block no-caret class="dropdown-filters" variant="outline-gray-light" menu-class="w-100" ref="filter_audience_location">
            <template #button-content> 
              <div class="d-flex flex-column justify-content-between h-100">
                <b-form-group class="mb-0">
                  <span class="text-left align-middle color-gray-light d-block mb-1">
                    <feather-icon icon="ImageIcon"></feather-icon>
                    {{$t("search.realty.containImage")}} 
                  </span>
                </b-form-group>
                <p class="text-left font-weight-bold m-0">{{ (Object.keys(image).length > 0) ? image.text : $t('search.realty.any')}}</p>
              </div>
            </template>
            <b-dropdown-header>
              <options-filters 
                :options="$t('search.realty.image_options')" 
                @optionsFilter="filterSelect" 
                :vertical="true"
                :name_prop="`image`"
                :key="update_image"
              />
            </b-dropdown-header>
          </b-dropdown>
        </b-col>

        <!-- Boton submit -->
        <b-col class="col-12 col-md-6 col-lg button-submit-searcher">
          <button-submit-filters 
            :searched="searched" 
            :loading="loading" 
            :text="getText()" 
            :total_results="total_results" 
            @sendFilters="sendFilters()" 
          />
        </b-col>
      </b-row>
    </b-card>

    <b-row class="m-0 d-flex flex-wrap">
      <b-col class="col-12 col-md-4 p-0 mb-1">
        <dropdown-filters 
          :icon="`MaximizeIcon`"
          :options="$t('search.realty.size_options')"
          :text="$t('search.realty.size')"
          @option_selected="optionSelected"
          :name_prop="`size`"
          :key="update_size"
        />
      </b-col>
      <b-col class="col-12 col-md-7 p-0 px-md-1">
        <b-card class="mr-0" id="card-search-by">
          <b-form-input 
              class="input-search-by-influencer" 
              :placeholder="$t('search.realty.placeholder_search')"
              v-model="query"  
          ></b-form-input>
        </b-card>
      </b-col>
      <b-col class="col-12 col-md-1 p-0">
        <div class="clear-filters-button">
          <b-button :title="$t('search.reset_filters')" class="d-flex justify-content-center" variant="outline" @click="clearFilters()">
            <b-icon icon="trash" class="cursor-pointer"/>
          </b-button>
        </div>        
      </b-col>
    </b-row>
    <b-row class="m-0 mb-1">
      <b-col class="p-0 col-12 col-md-6 col-lg-4 mb-1">
        <dropdown-filters
          :icon="`TypeIcon`"
          :options="$t('search.realty.type_options')"
          :text="$t('search.realty.type')"
          @option_selected="optionSelected"
          :name_prop="`type`"
          :key="update_type"
        />
      </b-col>
      <b-col class="p-0 col-12 col-md-6 pl-md-1 px-lg-1 col-lg-4 mb-1">
        <dropdown-filters
          :icon="`FileTextIcon`"
          :options="$t('search.realty.format_options')"
          :text="$t('search.realty.format')"
          @option_selected="optionSelected"
          :name_prop="`format`"
          :key="update_format"
        />
      </b-col>
      <b-col class="p-0 col-12 col-md-6 col-lg-4">
        <dropdown-filters
          :icon="`FileTextIcon`"
          :options="sub_format_options"
          :text="$t('search.realty.subformat')"
          @option_selected="optionSelected"
          :name_prop="`sub_format`"
          :key="update_subformat"
          :disabled="Object.keys(format).length > 0 ? false : true"
        />
      </b-col>
    </b-row>

    <apply-filters :filters="checkFiltersUsed()" @deleteFilter="deleteFilter"/>
  </div>
</template>

<script>
import {
  BCard, 
  BRow, 
  BCol, 
  BFormGroup, 
  BFormInput,
  BDropdown,
  BDropdownHeader,
  BButton,
} from 'bootstrap-vue';
import utils from '@/libs/utils';
import outofhome_service from '@/services/outofhome';
import OptionsFilters from '@/views/components/button-group/OptionsFilters.vue';
import buttonSubmitFilters from '@/views/buscador/buttonSubmitFilters.vue';
import locationsStateSuggest from '@/views/pages/outofhome/graphs/locationsStateSuggest.vue';
import dropdownFilters from '@/views/pages/outofhome/graphs/dropdownFilters.vue';
import applyFilters from '@/views/buscador/filters/applyFilters.vue';

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BDropdown,
    BDropdownHeader,
    OptionsFilters,
    buttonSubmitFilters,
    BButton,
    locationsStateSuggest,
    dropdownFilters,
    applyFilters,
  },
  data () {
    return {
      utils,
      size: {},
      type: {},
      format: {},
      sub_format: {},
      sub_format_options: [],
      availability: {},
      total_results: 0,
      loading: false,
      state: {},
      state_options: [],
      category: {},
      category_options: [],
      image: {},
      query: '',
      update_availability: false,
      update_state: false,
      update_category: false,
      update_image: false,
      searched: true,
      update_sub: false,
      update_location: false,
      update_size: false,
      update_type: false,
      update_format: false,
      update_subformat: false,
      filters: [
        {
          filter: 'availability',
          text: this.$t("search.realty.availability"),
          models: [{name: 'availability', value: null, default: {}}],
          value: null,
        },
        {
          filter: 'query',
          text: this.$t("search.realty.searched_by"),
          models: [{name: 'query', value: null, default: ''}],
          value: null,
        },
        {
          filter: 'state',
          text: this.$t("search.realty.state"),
          models: [{name: 'state', value: null, default: {}}],
          value: null,
          update_component: 'update_location'
        },
        {
          filter: 'category',
          text: this.$t("search.realty.category"),
          models: [{name: 'category', value: null, default: {}}],
          value: null,
        },
        {
          filter: 'image',
          text: this.$t("search.realty.containImage"),
          models: [{name: 'image', value: null, default: {}}],
          value: null,
        },
        {
          filter: 'size',
          text: this.$t("search.realty.size"),
          models: [{name: 'size', value: null, default: {}}],
          value: null,
          update_component: 'update_size',
        },
        {
          filter: 'type',
          text: this.$t("search.realty.type"),
          models: [{name: 'type', value: null, default: {}}],
          value: null,
          update_component: 'update_type',
        },
        {
          filter: 'format',
          text: this.$t("search.realty.format"),
          models: [{name: 'format', value: null, default: {}}],
          value: null,
          update_component: 'update_format',
        },
        {
          filter: 'subformat',
          text: this.$t("search.realty.subformat"),
          models: [{name: 'sub_format', value: null, default: {}}],
          value: null,
          update_component: 'update_subformat',
        },
      ],
    }
  },
  created () {
    this.getStates();
    this.getCategories();
    this.getCounter();
    this.searched = true;
  },
  computed: {
  },
  methods: {
    checkFiltersUsed() {
      return this.filters.filter((item) => item.models[0].value !== null);
    },
    clearFilters() {
      this.filters.forEach(item => {
        this.deleteFilter(item.filter, false)
      })
      this.searched = true;
      this.sendFilters();
      this.getCounter();
    },
    toggleCollapse(id) {
      if (this.$refs[`collapse_filters_${id}`]) {
        this.$refs[`collapse_filters_${id}`].classList.toggle('d-none');
        this.$refs[`chev_down_${id}`].classList.toggle('d-none');
        this.$refs[`chev_up_${id}`].classList.toggle('d-none');
      }
    },
    filterSelect(options, name_prop) {
      this[name_prop] = options;
      this[`update_${name_prop}`] = !this[`update_${name_prop}`];
    },
    locationSelected(location) {
      this.state = location;
      if (Object.keys(location).length !== 0) this.update_location = !this.update_location;
      else this.deleteFilter('state');
    },
    optionSelected(options, name_prop) {
      this[name_prop] = options;
    },
    getCategories() {
      outofhome_service.getCategories()
        .then(response => {
          this.category_options.push({"value": null, "text": this.$t('search.realty.any')})
          response.results.forEach(category => {
            this.category_options.push(category)
          })
        })
    },
    changeFilter(name_filter, new_models, value_filter) {
      const filter = this.filters.find(item => item.filter === name_filter)
      filter.models.forEach(model => {
        const new_model = new_models.find(mod => mod.name === model.name)
        if (new_model) {
          model.value = new_model.value
        }
      })
      filter.value = value_filter
    },
    deleteFilter(name, get_counter = true) {
      const to_deleted = this.filters.find((item) => item.filter === name);
      to_deleted.models.forEach((i) => {
        this[i.name] = i.default;
        i.value = null;
      });
      if (to_deleted.update_component) this[to_deleted.update_component] = !this[to_deleted.update_component];
      if (get_counter) this.getCounter();
      this.searched = true;
    },
    getStates() {
      outofhome_service.getStates()
        .then(response => {
          this.state_options.push({"value": null, "text": this.$t('search.realty.any')})
          response.results.forEach(state => {
            this.state_options.push(state)
          })
        })
    },
    getText() {
      if (this.loading) return this.$t("search.searching");
      if (this.total_results > 0 && this.searched) return `${utils.getFormat(this.total_results)} ${this.$t("search.results")}`;
      if (!this.searched) return `${utils.getFormat(this.total_results)} ${this.$t("search.showResults")}`;
      return this.$t('search.noResults');
    },
    getCounter() {
      this.searched = false;
      this.loading = true;
      const filters = this.getFilters();
      outofhome_service.searchLocations(filters)
        .then(response => {
          this.total_results = response.count;
          this.loading = false;
        })
    },
    getFilters() {
      const filters = {};
      const data = {
        size: this.size.value,
        type: this.type.value,
        format: this.format.value,
        sub_format: this.sub_format.value,
        availability: this.availability.value,
        state: this.state.value,
        category: this.category.value,
        image: this.image.value,
        query: this.query,
      }
      const dataKeys = Object.keys(data)
      for (let i = 0; i < dataKeys.length; i++) {
        const dataKey = dataKeys[i]
        if (data[dataKey] !== null) {
          filters[dataKey] = data[dataKey]
        }
      }

      return filters
    },
    sendFilters() {
      const filters = this.getFilters();
      this.$emit('childSearchFilters', filters);
      this.searched = true;
    }
  },
  watch : {
    format(val) {
      if (this.format) {
        this.sub_format_options = this.$t(`search.realty.${this.format.value}`);
        this.update_sub = !this.update_sub;
      } else {
        this.sub_format_options = [];
      }
      if (Object.keys(val).length > 0) {
        if (val.value === null) this.format = {}
        const new_models = [{name: 'format', value: val.value}]
        this.changeFilter('format', new_models, val.text);
      }
      this.getCounter();
    },
    sub_format(val) {
      if (Object.keys(val).length > 0) {
        const new_models = [{name: 'sub_format', value: val.value}]
        this.changeFilter('subformat', new_models, val.text);
      }
      this.getCounter();
    },
    query(val) {
      if (val.length > 0) {
        const new_models = [{name: 'query', value: val}]
        this.changeFilter('query', new_models, val);
      } else {
        this.deleteFilter('query', true)
      }
      this.getCounter();
    },
    size(val) {
      if (Object.keys(val).length > 0) {
        const new_models = [{name: 'size', value: val.value}]
        this.changeFilter('size', new_models, val.text);
      }
      this.getCounter();
    },
    type(val) {
      if (Object.keys(val).length > 0) {
        const new_models = [{name: 'type', value: val.value}]
        this.changeFilter('type', new_models, val.text);
      }
      this.getCounter();
    },
    availability(val) {
      if (Object.keys(val).length > 0) {
        const new_models = [{name: 'availability', value: val.value}];
        this.changeFilter('availability', new_models, val.text);
      }
      this.getCounter();
    },
    state(val) {
      if (Object.keys(val).length > 0) {
        const new_models = [{name: 'state', value: val.value}]
        this.changeFilter('state', new_models, val.text);
      }
      this.getCounter();
    },
    category(val) {
      if (Object.keys(val).length > 0) {
        const new_models = [{name: 'category', value: val.value}]
        this.changeFilter('category', new_models, val.text);
      }
      this.getCounter();
    },
    image(val) {
      if (Object.keys(val).length > 0) {
        const new_models = [{name: 'image', value: val.value}]
        this.changeFilter('image', new_models, val.text);
      }
      this.getCounter();
    }
  },
}
</script>

<style>
.buttonResults {
  background-color:#9449fa !important;
  border-color: #9449fa !important;
  margin-right: 10px;
}
.buttonResults:hover, .buttonResults:focus {
  background-color:#792fe3 !important;
  border-color: #792fe3 !important;
}
.buttom-submit-ooh {
  position: sticky; 
  bottom: 0; 
  border-top: #ebebeb solid 1px; 
  z-index: 9;
}
.filter-outline {
  border: 1px solid transparent;
  position: relative;
}
.filter-outline:hover {
  border: 1px solid #E2E8F0;
  border-radius: 8px;
  cursor: pointer;
  transition: all 250ms;
}
.dropdown-filters {
  height: 100% !important;
}
.dropdown-filters .btn {
  height: 100% !important;
  padding: 12px 16px !important;
}
.color-gray-light {
  color: #718096;
}
#card-filters-ooh .card-body {
  padding: 10px !important;
}
#card-filters-ooh {
  margin-bottom: 0.7em !important;
}
#card-search-by .card-body {
  padding: 0 0 0 0.5em;
  height: 41.5px;
  margin: 0 !important;
}
#card-search-by {
  margin-bottom: 0.7em !important;
}
.text-filter-under {
  color: #718096;
  font-size: 12px;
}
.dropdown-filters .dropdown-menu {
  max-height: 550px !important;
}
.input-search-by-influencer {
  border: none ;
  box-shadow: none;
}
.input-search-by-influencer:focus {
  box-shadow: none !important;
}
.clear-filters-button {
  margin-bottom: 0.7em;
}
.clear-filters-button > button {
  width: 100%;
  color: #82868b;
}
.more-filters-button, .clear-filters-button {
  background-color: white;
  box-shadow: 0 4px 24px 0 rgb(34 41 47 / 10%);
  border-radius: 0.42rem;
  height: 41.5px;
}
.more-filters-button > button {
  width: 100%;
  color: #009cec;
}
.more-filters-button > button:hover {
  color: #006394;
}
.filters_used_number {
  display: block;
  width: 1.4em;
  height: 1.4em;
  padding: 0.25em;
  background-color: #009cec;
  color: white;
  border-radius: 100%;
  font-size: 14px;
}
.toggle-collapse-filters:hover .chev-more-filters {
  color: #4b4b4b;
  transition: all 500ms;
}
</style>
