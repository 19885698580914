<template>
  <b-card-code title="Basic Map">
    <component
      :is="LMap"
      :zoom="zoom"
      :center="center"
    >
      <component :is="LTileLayer" :url="url" />
      <component
        :is="LCirlce"
        :lat-lng="circle.center"
        :radius="circle.radius"
        :color="circle.color"
      />
    </component>

    <template #code>
      {{ codeBasic }}
    </template>
  </b-card-code>
</template>

<script>
// import BCardCode from '@core/components/b-card-code/BCardCode.vue'
import { codeBasic } from './code';
import 'leaflet/dist/leaflet.css'

export default {
  components: {
    BCardCode: () => import('@core/components/b-card-code/BCardCode.vue'),
  },
  data() {
    return {
      url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
      zoom: 8,
      center: [47.313220, -1.319482],
      circle: {
        center: [47.413220, -1.0482],
        radius: 4500,
        color: '#EA5455',
      },
      codeBasic,
      LMap: null,
      LTileLayer: null,
      LCircle: null,
    }
  },
  async mounted() {
    const vue2_leaflet = (await import('vue2-leaflet'));

    this.LMap = vue2_leaflet.LMap;
    this.LTileLayer = vue2_leaflet.LTileLayer;
    this.LCircle = vue2_leaflet.LCircle;
  },
}
</script>

<style lang="scss">
.vue2leaflet-map{
  &.leaflet-container{
    height: 350px;
  }
}
</style>
