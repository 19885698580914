<template>
  <b-row class="match-height">
    <b-col cols="12">
      <leaflet-basic v-if="show"/>
      <b-card>
        <component
          :is="LMap"
          :zoom.sync="zoom"
          :options="mapOptions"
          :bounds="bounds"
          style="height: 500px; width: 100%"
          ref="map"
        >
          <component :is="LTileLayer" :url="url" />

          <component
            :is="LMarker"
            v-for="marker in locations"
            :key="marker.id"
            :visible="true"
            :draggable="false"
            :lat-lng.sync="marker.position"
          >
            <component :is="LPopup" :content="marker.tooltip" />
            <component :is="LTooltip" :content="marker.tooltip" />
          </component>
          <component :is="LControlZoom" :position="zoomPosition" />
        </component>
      </b-card>
    </b-col>
  </b-row>
</template>

<script>
import { 
  BRow,
  BCol ,
  BCard,
} from 'bootstrap-vue'
import LeafletBasic from '@/views/charts-and-maps/maps/leaflet/LeafletBasic.vue'

export default {
  props: {
    locations: {
      type: Array,
      default: () => [],
    },
    marker: {
      type: Object,
      default: () => {}
    }
  },
  components: {
    BCard,
    BRow,
    BCol,
    LeafletBasic,
  },
  data() {
    return {
      zoom: 8,
      show: false,
      url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
      mapOptions: {
        zoomControl: false,
        attributionControl: false,
        zoomSnap: true,
      },
      zoomPosition: 'topleft',
      bounds: null,
      LMap: null,
      LTileLayer: null,
      LControlZoom: null,
      LMarker: null,
      LTooltip: null,
      LPopup: null,
      latLngBounds: null,
      Icon: null,
    }
  },
  created() {
    console.log(this.marker, 'Aque')
    // TODO: pintar bien el mapa y hacer zoom al marker
  },
  async mounted() {
    const vue2_leaflet = (await import('vue2-leaflet'));
    const leaflet = (await import('leaflet'));

    this.latLngBounds = leaflet.latLngBounds;
    this.Icon = leaflet.Icon;
    this.bounds = this.latLngBounds(
      { lat: 14.1303191397, lng: -117.0556529104 },
      { lat: 32.5803068469, lng: -86.030154997 }
    )
    delete this.Icon.Default.prototype._getIconUrl
    this.Icon.Default.mergeOptions({
      iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
      iconUrl: require('leaflet/dist/images/marker-icon.png'),
      shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
    })

    this.LMap = vue2_leaflet.LMap;
    this.LTileLayer = vue2_leaflet.LTileLayer;
    this.LControlZoom = vue2_leaflet.LControlZoom;
    this.LPopup = vue2_leaflet.LPopup;
    this.LMarker = vue2_leaflet.LMarker;
    this.LTooltip = vue2_leaflet.LTooltip;
  },
  methods: {
  }
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/map-leaflet.scss';
</style>
