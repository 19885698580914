<template>
  <b-sidebar 
    v-model="show" 
    right 
    shadow 
    backdrop
    class="sidebar-location"
    @change="hasClosedSidebar" 
    ref="sidebar_location"
  >
    <template #header>
      <div class="w-100 d-flex justify-content-between align-items-center p-1">
        <div class="d-block" target="_blanck">
          <strong class="text-dark">{{location.name}}</strong>
          <p class="text-muted m-0">
            {{location.category}}
          </p>
        </div>
        <feather-icon 
          icon="XIcon" 
          size="18" 
          class="close-sidebar-icon cursor-pointer"
          @click="show = false"
        >
        </feather-icon>
      </div>
    </template>
    <b-row class="m-0">
      <b-col class="d-flex justify-content-center">
        <b-avatar square :src="location.img" class="avatar-sidebar-locations rounded" icon="house-door-fill"></b-avatar>
      </b-col>

      <b-row class="mt-3 px-1 m-0">
        <b-col class="col-6">
          <div class="text-center">
            <h5>Total de impresiones</h5>
            <h3 class="numberGreen mt-2">{{utils.numberFormat(location.total_impressions)}}</h3>
          </div>
        </b-col>
        <b-col class="col-6">
          <div class="text-center">
            <h5>Impresiones por mes</h5>
            <h3 class="numberGreen mt-2">{{utils.numberFormat(location.month_impressions)}}</h3>
          </div>
        </b-col>
      </b-row>
      <b-col cols="12" class="mt-2 border-top pt-1">
        <b-row>
          <b-col cols="12">
            <p>
              <strong>ID: </strong>
              <span class="text-italic">{{location.external_id}}</span>
            </p>
            <p class="mt-1">
              <strong>Cliente: </strong>
              <span class="text-italic">{{customer}}</span>
            </p>
            <p class="mt-1">
              <strong>Dirección: </strong>
              <span class="text-italic">{{location.tooltip}}</span>
            </p>
          </b-col>

          <b-col cols="12">
            <p>
              <strong>Tamaño: </strong>
              <span class="text-italic">{{location.size}}</span>
            </p>
            <p class="mt-1">
              <strong>Tipo: </strong>
              <span class="text-italic">{{location.type}}</span>
            </p>
            <p class="mt-1">
              <strong>Formato: </strong>
              <span class="text-italic">{{location.format}}</span>
            </p>
          </b-col>
        </b-row>
      </b-col>
      <b-col>
        <b-row>
          <b-col cols="12" class="d-flex justify-content-center align-items-center">
            <age-range 
              :info="location.age_range" 
              v-if="location.age_range"
            />
            <socioeconomic-level 
              class="block mt-2" 
              :info="location.socioeconomic_level" 
              v-if="location.socioeconomic_level"
            />
          </b-col>
        </b-row>
      </b-col>
    </b-row>
  </b-sidebar>
</template>

<script>
import {
  BRow, 
  BCol,
  BAvatar,
  BSidebar,
} from 'bootstrap-vue'

import SocioeconomicLevel from '@/views/pages/outofhome/graphs/SocioeconomicLevel.vue'
import AgeRange from '@/views/pages/outofhome/graphs/AgeRange.vue'
import utils from '@/libs/utils'

export default {
  name: 'sidebarLocation',
  components: {
    BRow,
    BCol,
    BAvatar,
    SocioeconomicLevel,
    AgeRange,
    BSidebar,
  },
  props: {
    location: {
      type: Object,
      required: true
    },
    open_sidebar: {
      type: Boolean,
      required: true,
    },
  },
  data () {
    return {
      utils,
      customer: '',
      col: '',
      show: this.open_sidebar,
    }
  },
  created () {
    const userData = JSON.parse(localStorage.getItem('userData'));
    this.customer = userData.account_company.name;
  },
  methods: {
    hasClosedSidebar() {
      this.$refs.sidebar_location.$el.classList.remove('open-sidebar-locations');
    },
  }
}
</script>

<style>
  .sidebar-location > .b-sidebar {
    width: 350px !important;
  }
  .text-italic {
    font-style: italic;
  }
  .img {
    width: 100%;
    object-fit: cover;
    max-height: 250px;
  }
  .numberGreen {
    color: #78AE48;
    font-weight: bold;
  }
  .sidebar-location .bg-light{
    background-color: white !important;
  }
  .sidebar-location > .b-sidebar-backdrop .bg-dark,.sidebar-location > .b-sidebar-backdrop{
    background-color: transparent !important;
  }
  .sidebar-location .b-sidebar-header {
    padding: 1em 0 0 0;
    font-size: 13px !important;
  }
  .avatar-sidebar-locations {
    padding: 0.5em;
    background-color: #dcdcdc;
    width: 220px;
    height: 220px;
    border-radius: 1em !important;
    object-fit: cover;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
  }
</style>
 