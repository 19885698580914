<template>
  <div>
    <b-row>
      <b-col class="">
        <b-card class="card-actions-searcher-ooh">
          <b-col class="d-flex justify-content-between align-items-center flex-wrap p-0">
            <div class="mb-1 mb-sm-0 d-flex align-items-center">
               <b-button-group>
                <b-button variant="flat-secondary" class="button-change-view flat-secondary" @click="change_view_card = false"> <b-icon icon="list-task"></b-icon> {{$t('search.list_view')}}</b-button>
                <b-button variant="flat-secondary" class="button-change-view flat-secondary" @click="change_view_card = true"> <b-icon icon="grid3x3-gap"></b-icon> {{$t('search.mosaic')}}</b-button>
              </b-button-group>
              <span v-if="checked_location.length > 0" class="color-gray-button">
                {{$t('search.realty.spaces_avaible')}}: {{checked_location.length}}
              </span>
            </div>
            <div>
              <span class="margin-right-05 color-gray-button">{{$t('search.add_to')}}:</span>
              <dropdown-campaigns 
                :campaigns="campaigns" 
                :disabled="checked_location.length === 0" 
                :influencers="getFormatLocations()" 
                :multiple_added="true"
                :is_ooh="true"
                class="mr-1 mb-1 mb-sm-0"
              />
            </div>
          </b-col>
        </b-card>
      </b-col>
    </b-row>
    <div class="position-relative">
      <b-table
        :fields="$t('search.realty.table_searcher')"
        :items="locations"
        id="table-searcher"
        head-variant="light"
        v-if="!change_view_card"
        @row-clicked="rowTableClick"
      >
        <template #head(external_id)>
          <b-form-checkbox class="checkboxx-table-searcher" v-model="selected_all"></b-form-checkbox>
        </template> 
        <template #cell(external_id)="data">
          <div class="d-flex justify-content-between position-absolute">
            <b-form-checkbox
              class="checkbox-table-searcher d-none position-absolute-checkbox-searcher"
              v-model="checked_location"
              :value="data.index"
              :ref="`checkbox_table_${data.index}`"
            >
            </b-form-checkbox>
            <h6 class="id-table-searcher" :ref="`id_table_${data.index}`">{{getIndexTable(data.index)}}</h6>
          </div>
        </template>
        <template #cell(tooltip)="data">
          <div class="d-flex position-relative">
            <b-media vertical-align="center">
              <template #aside>
                <div>
                  <div :class="`avatar-rounded-searcher-ooh`">
                    <b-avatar size="lg" :src="data.item.img" variant="light-primary" icon="house-door-fill"/>
                    <b-avatar :src="data.item.img" square variant="light-primary" icon="house-door-fill" :class="`drop-img-searcher-ooh`"></b-avatar>
                  </div>
                </div>
              </template>
              <p class="font-weight-bold d-block mb-0" style="word-wrap: break-word;">
                {{ data.item.tooltip }}
              </p>
              <p>{{(data.item.external_id !== null) ? `#${data.item.external_id}`: '-'}}</p>
            </b-media>
          </div>
        </template>

        <template #cell(state)="data">
          <span v-if="data.item.state !== 'Default'">{{data.item.state}}</span>
          <span v-else>-</span>
        </template>

        <template #cell(availability)="data">
          <div style="margin: auto;width: 75%;">
            <span v-if="data.item.availability">
              <feather-icon
                icon="CheckCircleIcon"
                size="18"
                color="green"
              />
            </span>
            <span v-else>
              <feather-icon
                color="red"
                icon="MinusCircleIcon"
                size="18"
              />
            </span>
          </div>
        </template>
      </b-table>
      <b-row v-else>
        <b-col
          class="col-sm-12 col-md-6 col-lg-4 col-xl-3" 
          v-for="(marker, index) in locations" 
          :key="index"
        >
          <b-card class="cardResult">
            <div>
              <div  class="w-100">
                <div class="badges">
                  <b-badge variant="primary">
                    {{marker.category}}
                  </b-badge>
                </div>
                <b-form-checkbox
                  v-model="checked_location"
                  :value="index"
                  class="custom-control-primary checkBoxCard"
                >
                </b-form-checkbox>
                <div>
                  <b-img :src="getPreview(marker)" @error="errorLoad(marker)" :alt="marker.tooltip" img-left class="cardImage h-100 block"/>
                </div>
              </div>
              <div class="w-100 d-flex align-content-between flex-wrap p-1 cardBody">
                <div class="block w-100 mb-1">
                  <b-card-text class="textZoom font-weight-bold">
                    {{marker.tooltip}} 
                  </b-card-text>
                  <b-row>
                    <b-col v-if="marker.format">{{marker.format}}</b-col>
                    <b-col v-if="marker.state !== 'Default'"> {{marker.state}}</b-col>
                  </b-row>
                  <b-row>
                    <b-col> IDOH: {{marker.external_id ? marker.external_id : marker.id}}</b-col>
                  </b-row>
                </div>
                <div class="block w-100 border-top">
                  <div class="mt-1 d-flex justify-content-between">
                    <span class="font-weight-bold scopetext">Alcance: 1,200,000/mes</span>
                  </div>
                </div>
              </div>
            </div>
          </b-card>
        </b-col>

      </b-row>
      <div class="container-loader-searcher loader-table-cards" v-if="loader_results">
        <b-spinner class="spinner-table-searcher"></b-spinner>
      </div>
    </div>

    <b-modal @hide="hideMap()" centered size="lg" v-model="modal_map" hide-footer>
      <only-map :locations="locations" :marker="marker_to_map"/>
    </b-modal>

    <sidebar-location
      :open_sidebar="open_sidebar" 
      :key="update_sidebar"
      :location="data_sidebar"
      v-if="open_sidebar"
      class="open-sidebar-locations"
    />
  </div>
</template>

<script>
import {
  BTable, 
  BMedia, 
  BAvatar, 
  BRow, 
  BCol, 
  BButton,
  BButtonGroup,
  BModal,  
  BFormCheckbox, 
  BCard,
  BSpinner,
  BBadge,
  BCardText,
  BImg,
} from 'bootstrap-vue'
import { required } from "@validations";
import OnlyMap from '@/views/pages/outofhome/OnlyMap.vue';
import sidebarLocation from '@/views/pages/outofhome/sidebarLocation.vue';
import dropdownCampaigns from '@/views/campaings/dropdownCampaigns.vue';

export default {
  components: {
    BTable,
    BMedia,
    BAvatar,
    BRow,
    BCol,
    BButton,
    BButtonGroup,
    BModal,
    OnlyMap,
    BFormCheckbox,
    sidebarLocation,
    BCard,
    dropdownCampaigns,
    BSpinner,
    BBadge,
    BCardText,
    BImg,
  },
  props: {
    locations: {
      type: Array,
      required: true
    },
    campaigns: {
      type: Array,
      default: () => []
    },
    loader_results: {
      type: Boolean,
      default: false,
    },
    current_page: {
      type: Number,
      required: true
    },
  },
  data () {
    return {
      required,
      modal_map: false,
      show_map: false,
      marker_to_map: {},
      checked_location: [],
      selected_all: null,
      selected_row: {},
      change_view_card: true,
      image_default: require('@/assets/images/dashboard/imgDefault.png'),
      open_sidebar: false,
      update_sidebar: false,
      data_sidebar: {}
    }
  },
  created () {
  },
  methods: {
    getPreview(marker) {
      if (marker.img) return marker.img;
      else return  this.image_default;
    },
    errorLoad(marker) {
      marker.img = require('@/assets/images/dashboard/imgDefault.png');
    },
    checkCheckboxClasses(val) {
      if (val.length > 0) {
        for (let i = 0; i < this.locations.length; i++) {
          this.$refs[`checkbox_table_${i}`].$el.classList.remove('d-none')
          this.$refs[`checkbox_table_${i}`].$el.classList.remove('checkbox-table-searcher')
          this.$refs[`id_table_${i}`].classList.add('d-none')
        }
      } else {
        for (let i = 0; i < this.locations.length; i++) {
          this.$refs[`checkbox_table_${i}`].$el.classList.add('d-none')
          this.$refs[`checkbox_table_${i}`].$el.classList.add('checkbox-table-searcher')
          this.$refs[`id_table_${i}`].classList.remove('d-none') 
        }
      }
    },
    checkedAll() {
      this.locations.forEach((location, index) => {
        this.checked_location.push(index)
      })
    },
    zoomMarker(marker) {
      // Mapa con punto, pero poner todos los puntos de esa página
      this.modal_map = true;
      this.show_map = true;
      this.marker_to_map = marker;
    },
    getFormatLocations() {
      const format_data = [];
      this.checked_location.map((id_table) => {
        format_data.push(this.locations[id_table].id);
      });
      return format_data;
    },
    hideMap() {
      this.show_map = false;
      this.marker_to_map = {};
    },
    getIndexTable(index) {
      if (this.current_page > 1) return (index + 1) + (this.locations.length * (this.current_page - 1));
      else return index + 1;
    },
    rowTableClick(data) {
      this.open_sidebar = true; 
      this.update_sidebar = !this.update_sidebar;
      this.data_sidebar = data;
    },
  },
  watch: {
    checked_location(val) {
      if (val.length === 1) this.selected_row = this.locations[val[0]];
      if (!this.change_view_card) this.checkCheckboxClasses(val);
    },
    selected_all(val) {
      if (val) this.checkedAll();
      else this.checked_location = [];
    },
  }
}
</script>

<style>
.open-sidebar-locations > .b-sidebar-right {
  animation: sidebar 500ms;
}
.button-full-width {
  width: 100% !important;
  margin-block: 1px !important;
}
.thead-light th {
  background-color: white !important;
}
#table-searcher td {
  border-bottom: 1px solid #eff3f6;
}
#table-searcher tr {
  background-color: white;
}
#table-searcher tr:hover {
  background-color: #EFF3F6;
  cursor: pointer;
  transition: all 100ms;
}
#table-searcher-card .card-body {
  padding: 0;
}
.table-responsive {
  overflow: visible;
}
#table-searcher td {
  border-bottom: 1px solid #eff3f6;
}
#table-searcher tr {
  background-color: white;
}
#table-searcher tr:hover {
  background-color: #EFF3F6;
  cursor: pointer;
  transition: all 100ms;
}
.position-absolute-checkbox-searcher {
  position: absolute;
  top: -7px;
}
.checkbox-table-searcher {
  display: none !important;
}
#table-searcher tr:hover .checkbox-table-searcher {
  display: block !important;
}
#table-searcher tr:hover .id-table-searcher {
  display: none;
}
#table-searcher th {
  padding: 15px 18px 15px 28px !important;
}
.card-actions-searcher-ooh .card-body {
  padding: 1em !important;
}
.outline-secondary-hover-summary:disabled {
  border-color: #82868bbf !important;
  color: #82868bbf !important;  
}
.color-gray-button {
  color: #82868b;
  font-weight: 500;
  line-height: 5px;
}
.avatar-rounded-searcher-ooh:hover  .drop-img-searcher-ooh {
  display: flex;
}
.drop-img-searcher-ooh {
  height: 200px;
  width: 200px;
  padding: 0.5em;
  border-radius: 0.5em;
  background-color: white !important;
  box-shadow: rgba(17, 12, 46, 0.15) 0px 48px 100px 0px;
  position: absolute;
  z-index: 1000;
  top: -5em;
  left: 5em;
  display: none;
}
.drop-img-searcher-ooh > .b-avatar {
  width: 200px;
  height: 200px;
  object-fit: cover;
}
.container-loader-searcher {
  height: 100%;
  width: 100%;
  position: absolute;
  background-color: rgba(255, 255, 255, 0.8);
  z-index: 1000;
  right: 5px;
}
.loader-table-cards {
  top: 0px;
  z-index: 10;
  display: flex;
  justify-content: center;
}
.spinner-table-searcher {
  margin-top: 10em;
  height: 6em;
  width: 6em;
  color: #acacac;
}
/* cards changed view */
.cardImage {
  object-fit: cover;
  width: 100%;
  height: 1000px !important;
  max-height: 250px;
}
.cardResult:hover {
  box-shadow: rgba(0, 0, 0, 0.3) 0px 19px 38px, rgba(0, 0, 0, 0.22) 0px 15px 12px;
}
.cardResult:hover .textZoom {
  color: #7367F0;
}
.cardResult > div {
  padding: 0px;
}
.checkBoxCard {
  position: absolute;
  top: 0.7em;
  right: 0.5em;
}
.badges {
  position: absolute;
  top: 0.7em;
  left: 0.5em;
}
.b-sidebar-backdrop {
  background-color: rgba(34, 41, 47);
  animation: sidebar-black-bakground 300ms;
}
@keyframes sidebar {
  0% {
    transform: translateX(500px)
  }
  100% {
    transform: translateX(0px)
  }
}

</style>
 