<template>
    <div>
      <vue-apex-charts
        height="277"
        :options="options"
        :series="series_socioeconomic"
      >
      </vue-apex-charts>
    </div>
</template>

<script>
// import {
//   BRow, BCol, BImgLazy
// } from 'bootstrap-vue'
import VueApexCharts from 'vue-apexcharts'
export default {
  components: {
    VueApexCharts
  },
  props: {
    info: {
      type: Object,
      required: true
    },
  },
  data () {
    return {
      series_socioeconomic: [],
      options: {
        labels: [],
        chart: {
          type: 'donut',
          toolbar: {
            show: false,
          },
        },
        dataLabels: {
          enabled: false,
        },
        legend: { show: false },
        comparedResult: [2, -3, 8],
        stroke: { width: 0 },
        colors: [
          '#40AAEC',
          '#CD4D4D',
          '#1A66F3',
          '#CF302B',
          '#EC3F79',
          '#F0A23F',
          '#346297',
          '#64D862',
          '#E73631',
          '#924DF7',
          '#F5D90D',
          '#924DF7',
          '#924DF7',
          '#924DF7',
          '#924DF7',
          '#924DF7',
          '#924DF7',
          '#924DF7'
        ],
        plotOptions: {
          pie: {
            startAngle: -10,
            donut: {
              labels: {
                show: true,
                name: {
                  offsetY: 15,
                },
                value: {
                  offsetY: -15,
                  formatter(val) {
                    // eslint-disable-next-line radix
                    return `${val}%`
                  },
                },
                total: {
                  show: true,
                  offsetY: 15,
                  label: `Nivel socio economico`,
                  formatter: () => { return `` },
                },
              },
            },
          },
        },
        responsive: [
          {
            breakpoint: 1325,
            options: {
              chart: {
                height: 100,
              },
            },
          },
          {
            breakpoint: 1200,
            options: {
              chart: {
                height: 120,
              },
            },
          },
          {
            breakpoint: 1045,
            options: {
              chart: {
                height: 100,
              },
            },
          },
          {
            breakpoint: 992,
            options: {
              chart: {
                height: 120,
              },
            },
          },
        ],
        grid: {
          padding: {
            right: -20,
            bottom: -8,
            left: -20,
          },
        },
      },
    }
  },
  created () {
    this.options.labels = Object.keys(this.info)
    this.series_socioeconomic = Object.values(this.info)
    console.log(Object.keys(this.info), Object.values(this.info))
  },
  methods: {
  }
}
</script>

<style>
</style>
 