<template>
  <b-row>
    <b-col md="12">
      <filters @childSearchFilters="searchFilters"></filters>
    </b-col>

    <b-col cols="12">
      <table-locations 
        :locations="locations" 
        :campaigns="campaigns" 
        :key="change_table"
        :loader_results="loader_results"
        :current_page="current_page"
      />
    </b-col>

    <b-col cols="12" style="text-align: center" v-if="!loader_results">
      <ul role="menubar" aria-disabled="false" aria-label="Pagination" class="pagination my-0 b-pagination pagination-sm justify-content-center">
        <li role="presentation" :class="`page-item ${!has_prev ? 'disabled' : ''}`" aria-hidden="true" @click="firstPage()" >
          <span role="menuitem" type="button" aria-label="Go to first page" class="page-link">
            <span class="text-dark">&lt;&lt;</span>
          </span
          >
        </li>
        <li role="presentation" :class="`page-item ${current_page === 1 ? 'disabled' : ''}`" aria-hidden="true" @click="prevPage()" >
          <span role="menuitem" type="button" aria-label="Go to previous page" class="page-link">
            <span class="text-dark">&lt;</span>
          </span>
        </li>
        <li role="presentation" class="page-item active">
          <button role="menuitemradio" type="button" aria-label="Go to page 1" aria-checked="true" aria-posinset="1" aria-setsize="43789" tabindex="0" class="page-link">
            <b>{{current_page}}</b>
          </button>
        </li>
        <li role="presentation" :class="`page-item ${!has_next ? 'disabled' : ''}`" aria-hidden="true" @click="nextPage()">
          <button role="menuitem" type="button" tabindex="-1" aria-label="Go to next page" class="page-link">
            <span class="text-dark">&gt;</span>
          </button>
        </li>
        <li role="presentation" :class="`page-item ${!has_next ? 'disabled' : ''}`" aria-hidden="true" @click="lastPage()" >
          <button role="menuitem" type="button" tabindex="-1" aria-label="Go to last page" class="page-link">
            <span class="text-dark">&gt;&gt;</span>
          </button>
        </li>
      </ul>
    </b-col>

  </b-row>
</template>

<script>
import {
  BRow, 
  BCol, 
} from 'bootstrap-vue'
import filters from '@/views/pages/outofhome/filters.vue'
import tableLocations from '@/views/pages/outofhome/tableLocations.vue'
import outofhome_service from '@/services/outofhome'
import utils from '@/libs/utils'
import service_campaign from '@/services/dashboard'

export default {
  name: 'buscadorUbicaciones',
  components: {
    BRow,
    BCol,
    filters,
    tableLocations
  },
  data () {
    return {
      utils,
      locations: [],
      change_table: 0,
      next_page: null,
      current_page: 1,
      has_next: false,
      has_prev: false,
      total_locations: 0,
      campaigns: [],
      filters: {},
      loader_results: false,
    }
  },
  created () {
    this.getLocations()
    this.getCampaigns()
  },
  methods: {
    searchFilters(filters) {
      this.filters = filters;
      this.current_page = 1;
      this.getLocations();
    },
    firstPage() {
      if (this.current_page !== 1) {
        this.current_page = 1;
        this.getLocations();
      }
    },
    prevPage() {
      if (this.has_prev) {
        this.current_page = this.current_page - 1;
        this.getLocations();
      }
    },
    nextPage() {
      if (this.has_next) {
        this.current_page = this.current_page + 1;
        this.getLocations();
      }
    },
    lastPage() {
      const page = Math.round(this.total_locations / 160)
      if (page !== this.current_page && this.has_next) {
        this.current_page = page;
        this.getLocations();
      }
    },
    getLocations() {
      this.loader_results = true;
      outofhome_service.searchLocations(this.filters, this.current_page)
        .then(response => {
          this.locations = [];
          this.total_locations = response.count;
          this.buildLocations(response.results);
          this.change_table += 1;
          this.has_next = response.next !== null;
          this.has_prev = response.previous !== null;
          this.loader_results = false;
        })
    },
    getImage(marker) {
      if (marker.internal_image) return utils.getAssetUrl(marker.internal_image)
      return marker.external_image
    },
    buildLocations(locations) {
      locations.forEach(marker => {
        const obj = {}
        obj.id = marker.uuid
        obj.external_id = marker.external_id
        obj.position = {lat: marker.lat, lng: marker.lon}
        obj.name = marker.name
        obj.tooltip = marker.address ? marker.address : marker.name
        obj.img = this.getImage(marker)
        obj.category = marker.category
        obj.meta = marker.meta_data;
        obj.state = marker.state
        obj.size = marker.size
        obj.type = marker.type
        obj.format = marker.format
        obj.sub_format = marker.sub_format
        obj.availability = marker.availability
        obj.description = marker.description
        obj.video = marker.video
        obj.total_impressions = marker.total_impressions
        obj.month_impressions = marker.month_impressions
        obj.date_availability = marker.date_availability
        obj.socioeconomic_level = marker.socioeconomic_level
        obj.age_range = marker.age_range
        obj.images = marker.images
        obj.ready_profile = marker.ready_profile

        this.locations.push(obj)
      })
    },
    getCampaigns() {
      service_campaign.getCampaign()
        .then(response => {
          response.response.results.filter((item) => {
            const obj = {value:item.uuid, text: item.name}
            this.campaigns.push(obj)
          });
        });
    },
  }
}
</script>
 